import React from "react";
import { useInView } from "react-intersection-observer";
import CountUp from "react-countup";
import Link from "next/link";
import { useRouter } from "next/router";

function CmsText({ component, page_id = "" }) {

  const { locale} = useRouter()

  const [ref, inView] = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });

  const scrollHandle = (e) => {
      e.preventDefault();
      var splitter = locale == 'en' ? '/' : '/'+locale;
      document.querySelector(e.target.getAttribute('href').split(splitter).join('')).scrollIntoView({
          behavior: 'smooth'
      });
   }

  if (component.id == "counter") {
    return (
      <div className={`h-full text`} id={`${component.id ?? ""}`} ref={ref}>
        <CountUp
          end={inView ? parseInt(stripHtml(component.options.content).replace(/\D/g, "")) : 0}
          separator={"."}
          duration={2}
        />
      </div>
    );
  } else {
    return (
      <div className={`h-full text`} id={`${component.id ?? ""}`}>
      {component.id == 'text-1667491372' ? 
        <Link href="#services" id="down_btn" onClick={scrollHandle}></Link>
      : ''}
        <div dangerouslySetInnerHTML={{ __html: component.options.content }} />
      </div>
    );
  }
}

function stripHtml(html)
{
   let tmp = document.createElement("DIV");
   tmp.innerHTML = html;
   return tmp.textContent || tmp.innerText || "";
} 

export default CmsText;
